// @ts-nocheck
import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import {
  GET_COMMUNICATION_ACTIVITY,
  SEND_SMS,
  SEND_EMAIL,
  GET_CHILDREN_EMAIL,
  GET_HISTORY_ACTIVITY,
  GET_TOKEN_PHONE_CALL,
  CALL_END,
  SEND_FACEBOOK_MESSAGE,
  SEND_INSTAGRAM_MESSAGE,
  GET_LOYALTY_ACTIVITY,
} from "../constants/Activity";

import ActivityService from "../../services/ActivityService";
import {
  setCommunicationActivity,
  setChildrenEmail,
  setHistoryActivity,
  setTokenPhoneCall,
  addDataToActivity,
  getHistoryActivity,
} from "../actions/Activity";
import {
  addMessageToConversation,
  getConversationForAllContacts,
} from "../actions/Conversation";
import { NUMBER_ACTIVITY_PER_PAGE } from "constants/ContactConstant";
import {
  selectFilterValue,
  selectSearchValue,
} from "./../selectors/Conversations";
import { message } from "antd";

function* getConversation() {
  try {
    const filterValue = yield select(selectFilterValue);
    const searchValue = yield select(selectSearchValue);
    let filter = {
      search: searchValue,
      filter: filterValue,
      page: 1,
      perPage: NUMBER_ACTIVITY_PER_PAGE,
    };
    yield put(getConversationForAllContacts(filter));
  } catch (error) {
    console.log(error);
  }
}

export function* getCommunicationActivity() {
  yield takeEvery(GET_COMMUNICATION_ACTIVITY, function* ({ payload }) {
    try {
      const activities = yield call(
        ActivityService.getCommunicationActivities,
        payload.page,
        payload.perPage,
        payload.id,
        payload.search
      );
      const data = { id: payload.id, data: activities.data };
      yield put(setCommunicationActivity(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* sendSMS() {
  yield takeEvery(SEND_SMS, function* ({ payload }) {
    try {
      const sms = yield call(ActivityService.sendSMS, payload);
      const data = { id: sms.data.contact.id, data: sms.data };
      yield put(addDataToActivity(data));
      yield call(getConversation);
      yield put(addMessageToConversation(data));
    } catch (err) {
      console.log(err);
      if (err.response?.data?.non_field_errors[0]) {
        message.error(err.response?.data?.non_field_errors[0], 3);
      } else {
        message.error(
          "Something went wrong while sending the SMS, please check the phone number.",
          3
        );
      }
    }
  });
}

export function* sendEmail() {
  yield takeEvery(SEND_EMAIL, function* ({ payload }) {
    try {
      const email = yield call(ActivityService.sendEmail, payload);
      const data = { id: email.data.contact.id, data: email.data };
      yield put(addDataToActivity(data));
      yield call(getConversation);
      yield put(addMessageToConversation(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getChildrenEmail() {
  yield takeEvery(GET_CHILDREN_EMAIL, function* ({ payload }) {
    try {
      const children = yield call(ActivityService.getChildrenEmail, payload);
      const data = { id: payload, data: children.data };
      yield put(setChildrenEmail(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getHistoryActivitySaga() {
  yield takeEvery(GET_HISTORY_ACTIVITY, function* ({ payload }) {
    try {
      const activities = yield call(
        ActivityService.getActivityHistory,
        payload.page,
        payload.perPage,
        payload.id,
        payload.content
      );
      const data = {
        id: payload.id,
        currentPage: payload.page,
        data: activities.data,
      };
      yield put(setHistoryActivity(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getLoyaltyHistoryActivitySaga() {
  yield takeEvery(GET_LOYALTY_ACTIVITY, function* ({ payload }) {
    try {
      const activities = yield call(
        ActivityService.getLoyaltyActivityHistory,
        payload.page,
        payload.perPage,
        payload.id,
        payload.content
      );
      const data = {
        id: payload.id,
        currentPage: payload.page,
        data: activities.data,
      };
      yield put(setHistoryActivity(data));
    } catch (err) {
      console.log(err);
    }
  });
}
export function* getTokenPhoneCall() {
  yield takeEvery(GET_TOKEN_PHONE_CALL, function* () {
    try {
      const token = yield call(ActivityService.getTokenPhoneCall);
      yield put(setTokenPhoneCall(token.data.token));
    } catch (err) {
      console.log(err);
    }
  });
}
export function* callEnd() {
  yield takeEvery(CALL_END, function* () {
    try {
      const activity = yield call(ActivityService.endCall);
      const data = { id: activity.data.contact.id, data: activity.data };
      yield put(addDataToActivity(data));
      yield put(
        getHistoryActivity({
          id: activity.data.contact.id,
          page: 1,
          perPage: NUMBER_ACTIVITY_PER_PAGE,
          content: "",
        })
      );
      yield call(getConversation);
      yield put(addMessageToConversation(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* sendFacebookMessage() {
  yield takeEvery(SEND_FACEBOOK_MESSAGE, function* ({ payload }) {
    try {
      const message = yield call(ActivityService.sendFacebookMsg, payload);
      const data = { id: message?.data?.contact?.id, data: message?.data };
      yield put(addDataToActivity(data));
      yield call(getConversation);
      yield put(addMessageToConversation(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* sendInstagramMessage() {
  yield takeEvery(SEND_INSTAGRAM_MESSAGE, function* ({ payload }) {
    try {
      const message = yield call(ActivityService.sendInstagramMsg, payload);
      const data = { id: message?.data?.contact?.id, data: message?.data };
      yield put(addDataToActivity(data));
      yield call(getConversation);
      yield put(addMessageToConversation(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCommunicationActivity),
    fork(sendSMS),
    fork(sendEmail),
    fork(getChildrenEmail),
    fork(getHistoryActivitySaga),
    fork(getTokenPhoneCall),
    fork(callEnd),
    fork(sendFacebookMessage),
    fork(sendInstagramMessage),
    fork(getLoyaltyHistoryActivitySaga),
  ]);
}
