import { defineMessages } from "react-intl";

export const scope = "form_builder";

export default defineMessages({
  formBuilderHeaderTitle: {
    id: `${scope}.title.header`,
    defaultMessage: "Form Builder",
  },
  formListHeaderTitle: {
    id: `${scope}.builder.title.header`,
    defaultMessage: "Forms",
  },
  searchText: {
    id: `${scope}.table.search.placeholder`,
    defaultMessage: "Search...",
  },
  editForm: {
    id: `${scope}.table.button.edit_form`,
    defaultMessage: "Edit",
  },
  copyForm: {
    id: `${scope}.table.button.copy_form`,
    defaultMessage: "Copy",
  },
  deleteForm: {
    id: `${scope}.table.button.delete_form`,
    defaultMessage: "Delete",
  },
  renameForm: {
    id: `${scope}.table.button.rename_form`,
    defaultMessage: "Rename",
  },
  name: {
    id: `${scope}.table.header.name`,
    defaultMessage: "Name",
  },
  formID: {
    id: `${scope}.table.header.form_id`,
    defaultMessage: "Form ID",
  },
  status: {
    id: `${scope}.table.header.status`,
    defaultMessage: "Status",
  },
  campaign: {
    id: `${scope}.table.header.campaign`,
    defaultMessage: "Campaign",
  },
  campaignLabel: {
    id: `${scope}.modal.campaignLabel`,
    defaultMessage: "Select Campaign (You'll see only Published ones)",
  },
  campaignsInfo: {
    id: `${scope}.modal.campaignsInfo`,
    defaultMessage:
      "If a form has a campaign, it cannot be used on landing page.",
  },
  newFormButton: {
    id: `${scope}.button.new_form_button`,
    defaultMessage: "New Form",
  },

  newFormNameLabel: {
    id: `${scope}.new_form.label.name`,
    defaultMessage: "Name",
  },
  emptyFieldError: {
    id: `${scope}.new_form.error.empty_field`,
    defaultMessage: "Name is required",
  },
  nameLengthConstraint: {
    id: `${scope}.new_form.error.max_length`,
    defaultMessage: "Ensure this field has no more than 255 characters",
  },
  confirmButton: {
    id: `${scope}.new_form.button.confirm`,
    defaultMessage: "Save",
  },
  cancelButton: {
    id: `${scope}.new_form.button.cancel`,
    defaultMessage: "Cancel",
  },
  copyButton: {
    id: `${scope}.modal.button.copy`,
    defaultMessage: "Copy",
  },
  copyTitle: {
    id: `${scope}.modal.title.copy`,
    defaultMessage: "Copy Form",
  },
  renameTitle: {
    id: `${scope}.modal.title.rename`,
    defaultMessage: "Rename Form",
  },
  renameButton: {
    id: `${scope}.modal.button.rename`,
    defaultMessage: "Rename",
  },
  deleteButton: {
    id: `${scope}.modal.button.delete`,
    defaultMessage: "Delete",
  },
  deleteTitle: {
    id: `${scope}.modal.title.delete`,
    defaultMessage: "Confirmation",
  },
  deleteDescription: {
    id: `${scope}.modal.description.delete`,
    defaultMessage: "Are you sure you want to delete this form?",
  },
  nameAlreadyExists: {
    id: `${scope}.builder.error.name_already_exists`,
    defaultMessage: "Form with that name already exists",
  },
  createSuccessful: {
    id: `${scope}.builder.success.create`,
    defaultMessage: "Form created!",
  },
  updateSuccessful: {
    id: `${scope}.builder.success.update`,
    defaultMessage: "Form updated!",
  },
  deleteSuccessful: {
    id: `${scope}.builder.success.delete`,
    defaultMessage: "Form deleted!",
  },
  copySuccessful: {
    id: `${scope}.builder.success.copy`,
    defaultMessage: "Form copied!",
  },
  renameSuccessful: {
    id: `${scope}.builder.success.rename`,
    defaultMessage: "Form renamed!",
  },
  saveFormlabel: {
    id: `${scope}.builder.form.label.save_form`,
    defaultMessage: "Save Form",
  },
  integrateFormText: {
    id: `${scope}.builder.form.integrate_form`,
    defaultMessage: "Integrate Form",
  },
  integrateTextDescription: {
    id: `${scope}.builder.form.description`,
    defaultMessage:
      "Use the following options to integrate your custom { embedItem } into your website",
  },
  iframeText: {
    id: `${scope}.builder.form.iframe`,
    defaultMessage: "Embed code",
  },
  iframeCopyText: {
    id: `${scope}.builder.form.iframe.copy`,
    defaultMessage:
      "Copy the link below and easily share it anywhere you wish.",
  },
  formText: {
    id: `${scope}.form.text`,
    defaultMessage: "Form",
  },
  redirectLabel: {
    id: `${scope}.redirect.label`,
    defaultMessage: "After form submit redirect to page:",
  },
  redirectPlaceholder: {
    id: `${scope}.redirect.placeholder`,
    defaultMessage: "URL",
  },
  submitMessageLabel: {
    id: `${scope}.message.label`,
    defaultMessage: "Edit thank you message:",
  },
  submitMessagePlaceholder: {
    id: `${scope}.message.placeholder`,
    defaultMessage: "Thank you for submiting your data!",
  },
});
