// @ts-nocheck
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import { push } from "connected-react-router";
import HtmlBuilderService from "../../services/HtmlBuilderService";
import {
  POST_NEW_TEMPLATE,
  DELETE_TEMPLATE,
  GET_ALL_TEMPLATES,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_CONTENT,
  GET_TEMPLATE_BY_ID,
  TEST_SEND_EMAIL,
  GET_EMAIL_FORM_TEMPLATES,
  CREATE_EMAIL_FORM_TEMPLATE,
  SEND_MASS_EMAIL,
  GET_OVERALL_EMAIL_STATS,
  GET_EMAIL_STATS,
  GET_EMAIL_CONTACTS,
} from "./../constants/HtmlBuilder";
import { NUMBER_PER_PAGE } from "./../../constants/HtmlBuilderConstant";
import {
  setAllTemplates,
  getAllTemplates,
  updateTemplateList,
  setTemplateById,
  setEmailFormTemplates,
  setCreatedTemplateId,
  setOvarallEmailStats,
  setEmailStats,
  setEmailContacts,
} from "./../actions/HtmlBuilder";
import { ROUTES } from "routes";

export function* postTemplate() {
  yield takeEvery(
    POST_NEW_TEMPLATE,
    function* ({ payload, messageSuccess, resetModal }) {
      try {
        yield call(HtmlBuilderService.postNewTemplate, payload);
        message.success(messageSuccess, 3);
        yield put(getAllTemplates({ page: 1, perPage: NUMBER_PER_PAGE }));
        resetModal();
        yield put(push(ROUTES.HTML_BUILDER));
      } catch (err) {
        console.log(err);
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors, 3);
        }
      }
    }
  );
}

export function* sendMassEmail() {
  yield takeEvery(
    SEND_MASS_EMAIL,
    function* ({payload, messageSuccess}) {
      try {
        yield call(HtmlBuilderService.sendMassEmail, payload);
        message.success(messageSuccess, 3);
      } catch (err) {
          console.log(err);
          message.error(err.response.data.message, 3);
      }
    }
  )
}

export function* updateTemplate() {
  yield takeEvery(
    UPDATE_TEMPLATE,
    function* ({ payload, messageSuccess, resetModal }) {
      try {
        const updateTemp = yield call(
          HtmlBuilderService.updateTemplate,
          payload
        );
        message.success(messageSuccess, 3);
        yield put(updateTemplateList(updateTemp.data));
        if (resetModal) {
          resetModal();
        }
      } catch (err) {
        console.log(err);
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors, 3);
        }
      }
    }
  );
}

export function* updateTemplateContent() {
  yield takeEvery(
    UPDATE_TEMPLATE_CONTENT,
    function* ({ payload, messageSuccess, redirect }) {
      try {
        yield call(HtmlBuilderService.updateTemplateContent, payload);
        message.success(messageSuccess, 3);
        if (redirect) {
          yield put(push(ROUTES.HTML_BUILDER));
        };
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* deleteTemplate() {
  yield takeEvery(DELETE_TEMPLATE, function* ({ payload, pageNumber }) {
    try {
      yield call(HtmlBuilderService.deleteTemplate, payload);
      yield put(
        getAllTemplates({ page: pageNumber, perPage: NUMBER_PER_PAGE })
      );
    } catch (err) {
      console.log(err);
      message.error(err.response.data.message, 3);
    }
  });
}

export function* getAllTemplatesSaga() {
  yield takeEvery(GET_ALL_TEMPLATES, function* ({ payload }) {
    try {
      const results = yield call(HtmlBuilderService.getAllTemplates, payload);
      yield put(setAllTemplates(results));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getTemplateByIdSaga() {
  yield takeEvery(GET_TEMPLATE_BY_ID, function* ({ payload }) {
    try {
      yield put(setTemplateById(null));
      const response = yield call(HtmlBuilderService.getTemplateById, payload);
      yield put(setTemplateById(response?.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* testSendEmail() {
  yield takeEvery(TEST_SEND_EMAIL, function* ({ payload, messageSuccess }) {
    try {
      yield call(HtmlBuilderService.testSendEmail, payload);
      message.success(messageSuccess, 3);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getEmailFormTemplates() {
  yield takeEvery(GET_EMAIL_FORM_TEMPLATES, function* () {
    try {
      const response = yield call(HtmlBuilderService.getEmailFormTemplates);
      yield put(setEmailFormTemplates(response?.data?.results));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* createEmailFormTemplate() {
  yield takeEvery(CREATE_EMAIL_FORM_TEMPLATE, function* ({payload, successMessage, setCreationDisabled, setIsLoading, redirect}) {
    try {
      const response = yield call(HtmlBuilderService.createEmailFormTemplates, payload);
      yield put(setCreatedTemplateId(response?.data?.id))
      message.success(successMessage, 3);
      setIsLoading(false);
      if (redirect) {
        yield put(push(ROUTES.HTML_BUILDER));
      }
    } catch (err) {
      setCreationDisabled(true);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* getOvarallEmailStats() {
  yield takeEvery(GET_OVERALL_EMAIL_STATS, function* ({ payload }) {
    try {
      const response = yield call(HtmlBuilderService.getOverallEmailStats, payload);
      yield put(setOvarallEmailStats(response?.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getEmailStats() {
  yield takeEvery(GET_EMAIL_STATS, function* ({ payload }) {
    const {id, page, perPage} = payload;
    try {
      const response = yield call(HtmlBuilderService.getEmailStats, id, page, perPage);
      yield put(setEmailStats(response?.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* getEmailContacts() {
  yield takeEvery(GET_EMAIL_CONTACTS, function* ({ payload }) {
    try {
      const response = yield call(HtmlBuilderService.getEmailContacts, payload);
      const { analyticsId } = payload;
      yield put(setEmailContacts({data: response?.data, analyticsId}));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(postTemplate),
    fork(deleteTemplate),
    fork(getAllTemplatesSaga),
    fork(updateTemplate),
    fork(getTemplateByIdSaga),
    fork(testSendEmail),
    fork(getEmailFormTemplates),
    fork(createEmailFormTemplate),
    fork(updateTemplateContent),
    fork(sendMassEmail),
    fork(getOvarallEmailStats),
    fork(getEmailStats),
    fork(getEmailContacts),
  ]);
}
