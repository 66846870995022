import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  GET_CONTACT_NOTES: "contacts/%s/notes/?page=%s&perPage=%s",
  NOTES_ITEM: "contacts/%s/notes/",
  SINGLE_NOTE: "contacts/%s/notes/%s/",
};

class NoteService extends ApiService {
  getContactNotesByPage = (page, perPage, id) => {
    return this.apiClient.get(format(ENDPOINTS.GET_CONTACT_NOTES, id, page, perPage));
  };

  postNewNote = ({ id, data }) => {
    return this.apiClient.post(format(ENDPOINTS.NOTES_ITEM, id), data);
  };

  deleteNote = ({ contactId, id }) => {
    return this.apiClient.delete(format(ENDPOINTS.SINGLE_NOTE, contactId, id));
  };
  updateNote = ({ contactId, id, data }) => {
    return this.apiClient.put(format(ENDPOINTS.SINGLE_NOTE, contactId, id), data);
  };
}

export default new NoteService();
