import { message } from "antd";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  setContactNotes,
  addNewNote,
  removeNote,
  setUpdatedNote,
} from "redux/actions/Notes";
import {
  DELETE_NOTE,
  GET_CONTACT_NOTES_BY_PAGE,
  POST_NEW_NOTE,
  UPDATE_NOTE,
} from "redux/constants/Notes";
import NoteService from "services/NoteService";

export function* getContactNotesByPageSaga() {
  yield takeEvery(GET_CONTACT_NOTES_BY_PAGE, function* ({ payload }) {
    try {
      const result = yield call(
        NoteService.getContactNotesByPage,
        payload.page,
        payload.perPage,
        payload.id
      );
      const data = {
        id: payload.id,
        currentPage: payload.page,
        data: result.data,
      };
      yield put(setContactNotes(data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* postNewNotesSaga() {
  yield takeEvery(POST_NEW_NOTE, function* ({ data, callBack }) {
    try {
      const result = yield call(NoteService.postNewNote, data);
      message.success(data.messageSuccess, 3);
      callBack();
      yield put(addNewNote(result));
    } catch (err) {
      err.response.data && message.error(err.response.data.text[0], 3);
      console.log(err);
    }
  });
}

export function* deleteNotesSaga() {
  yield takeEvery(DELETE_NOTE, function* ({ payload }) {
    try {
      yield call(NoteService.deleteNote, payload);
      yield put(removeNote(payload));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateNoteSaga() {
  yield takeEvery(UPDATE_NOTE, function* ({ payload, callBack }) {
    try {
      const result = yield call(NoteService.updateNote, payload);
      callBack(false);
      yield put(setUpdatedNote(result.data));
    } catch (err) {
      err.response.data && message.error(err.response.data.text[0], 3);
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getContactNotesByPageSaga),
    fork(postNewNotesSaga),
    fork(deleteNotesSaga),
    fork(updateNoteSaga),
  ]);
}
