// @ts-nocheck
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  GET_APPOINTMENTS,
  NEW_APPOINTMENT,
  EDIT_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_CALENDAR,
} from "../constants/Appointment";
import {
  deleteAppointmentSuccess,
  editAppointmentSuccess,
  getAppointmentsSuccess,
  newAppointmentSuccess,
  showMessage,
  getCalendarSuccess,
} from "../actions/Appointment";
import AppointmentService from "services/AppointmentService";
import { message } from "antd";
import {
  getCommunicationActivity,
  updateActivity,
  updateHistoryActivity,
  getHistoryActivity,
} from "../actions/Activity";
import {
  NUMBER_ACTIVITY_PER_PAGE,
  NUMBER_PER_PAGE,
} from "constants/ContactConstant";

export function* newAppointment() {
  yield takeEvery(NEW_APPOINTMENT, function* ({ payload }) {
    const { data, successfulCallback, messageSuccess } = payload;
    try {
      const { data: dataSet } = yield call(
        AppointmentService.createAppointment,
        data
      );
      successfulCallback();
      message.success(messageSuccess, 3);
      yield put(newAppointmentSuccess(dataSet));
      yield put(
        getHistoryActivity({
          id: dataSet.contact.id,
          page: 1,
          perPage: NUMBER_ACTIVITY_PER_PAGE,
          content: "",
        })
      );
      yield put(
        getCommunicationActivity({
          page: 1,
          perPage: NUMBER_PER_PAGE,
          id: dataSet.contact.id,
          search: "",
        })
      );
    } catch (err) {
      let errorMessage = err?.response?.data?.non_field_errors[0];
      errorMessage && message.error(errorMessage, 3);
    }
  });
}

export function* appointmentsGet() {
  yield takeEvery(
    GET_APPOINTMENTS,
    function* ({ payload, month = null, year = null }) {
    try {
      const { page, perPage } = payload;
      const data = yield call(
          AppointmentService.getAppointments,
          month,
          year,
          page,
          perPage
        );
      yield put(getAppointmentsSuccess(data));
    } catch (err) {
      yield put(showMessage(err.response.data.message));
    }
  }
  );
}

export function* appointmentsEdit() {
  yield takeEvery(EDIT_APPOINTMENT, function* ({ payload }) {
    const { data, successfulCallback, messageSuccess } = payload;
    try {
      const { data: dataSet } = yield call(
        AppointmentService.editAppointment,
        data
      );
      yield put(editAppointmentSuccess(dataSet));
      successfulCallback();
      yield put(updateActivity({ data: dataSet, cancel: false }));
      yield put(updateHistoryActivity({ data: dataSet, cancel: false }));
      message.success(messageSuccess, 3);
    } catch (err) {
      let errorMessage = err?.response?.data?.non_field_errors[0];
      errorMessage && message.error(errorMessage, 3);
    }
  });
}

export function* appointmentsDelete() {
  yield takeEvery(DELETE_APPOINTMENT, function* ({ payload }) {
    try {
      const data = yield call(AppointmentService.deleteAppointment, payload);
      yield put(deleteAppointmentSuccess(payload?.id));
      yield put(updateActivity({ data: data?.data, cancel: true }));
      yield put(updateHistoryActivity({ data: data?.data, cancel: true }));
    } catch (err) {
      yield put(showMessage(err?.response?.data?.message[0]));
    }
  });
}

export function* getCalendar() {
  yield takeEvery(GET_CALENDAR, function* ({ month = null, year = null }) {
    try {
      const payload = month ? { month: month, year: year } : null;
      const data = yield call(AppointmentService.getCalendar, payload);
      yield put(getCalendarSuccess(data.data));
    } catch (err) {
      yield put(showMessage(err.response.data.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(newAppointment),
    fork(appointmentsGet),
    fork(appointmentsEdit),
    fork(appointmentsDelete),
    fork(getCalendar),
  ]);
}
