import { stringify } from "querystring";
import ApiService from "./ApiService";
import { format } from "util";
import { ORDERING } from "constants/ApiConstant";
import { DEFAULT_ORDERING, DEFAULT_ORDER_FIELD } from "constants/DoctorsConstants";

const ENDPOINTS = {
  DOCTORS_LIST: "doctors/",
  DOCTOR_ITEM: "doctors/%s/",
  DOCTOR_TITLES: "doctor-title/",
  EMPLOYEES: "employees/",
  EMPLOYEES_SCHEDULER: "all/employees/",
  EMPLOYEE_PERMISSIONS: "users/%s/permissions/",
  EMPLOYEE_PERMISSIONS_ITEM: "users/%s/permissions/%s/",
};

class DoctorsServices extends ApiService {
  getDoctorsWithPagination = (page, perPage, search, order = "", orderField = "") => {
    const queryStringParams = {
      page,
      per_page: perPage,
      search: search,
      ordering: `${order === ORDERING.DESC ? "-" : ""}${orderField},${
        orderField !== DEFAULT_ORDER_FIELD ? DEFAULT_ORDERING : ""
      }`,
    };
    return this.apiClient.get(`${ENDPOINTS.DOCTORS_LIST}?${stringify(queryStringParams)}`);
  };

  deleteDoctor = (data) => {
    return this.apiClient.delete(format(ENDPOINTS.DOCTOR_ITEM, data));
  };
  createNewDoctor = (data) => {
    return this.apiClient.post(ENDPOINTS.DOCTORS_LIST, data);
  };

  getDoctor = (data) => {
    return this.apiClient.get(format(ENDPOINTS.DOCTOR_ITEM, data));
  };

  updateDoctor = (data, id) => this.apiClient.put(format(ENDPOINTS.DOCTOR_ITEM, id), data);

  getDoctorsTitle = () => {
    return this.apiClient.get(format(ENDPOINTS.DOCTOR_TITLES));
  };

  getEmployeesNonPagintaion = () => {
    return this.apiClient.get(ENDPOINTS.EMPLOYEES);
  };

  getEmployeesForScheduler = () => {
    return this.apiClient.get(ENDPOINTS.EMPLOYEES_SCHEDULER);
  };

  getEmployeesPermissions = (data) => {
    return this.apiClient.get(format(ENDPOINTS.EMPLOYEE_PERMISSIONS, data));
  };

  addEmployeesPermission = (employeeId, permissionId) => {
    return this.apiClient.put(format(ENDPOINTS.EMPLOYEE_PERMISSIONS_ITEM, employeeId, permissionId));
  };

  removeEmployeesPermission = (employeeId, permissionId) => {
    return this.apiClient.delete(format(ENDPOINTS.EMPLOYEE_PERMISSIONS_ITEM, employeeId, permissionId));
  };
}

export default new DoctorsServices();
