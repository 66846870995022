// @ts-nocheck
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import SmsBuilderService from "../../services/SmsBuilderService";
import {
  POST_NEW_SMS_TEMPLATE,
  DELETE_SMS_TEMPLATE,
  GET_ALL_SMS_TEMPLATES,
  UPDATE_SMS_TEMPLATE,
  TEST_SEND_SMS,
  SEND_MASS_SMS,
  POST_NEW_TEMPLATE_AND_SEND_NOW,
  UPDATE_SMS_TEMPLATE_AND_SEND,
  CREATE_NEW_DEFAULT_TEMPLATE,
} from "./../constants/SmsBuilder";
import { NUMBER_PER_PAGE } from "./../../constants/HtmlBuilderConstant";
import {
  setAllTemplates,
  getTemplatesForPage,
  updateTemplateList,
  setSelectedTemplate,
} from "./../actions/SmsBuilder";

function* postTemplate({ payload, messageSuccess, resetModal }) {
  try {
    let result = yield call(SmsBuilderService.postNewTemplate, payload);
    message.success(messageSuccess, 3);
    yield put(getTemplatesForPage({ page: 1, perPage: NUMBER_PER_PAGE }));
    resetModal();
    return result;
  } catch (err) {
    if ("non_field_errors" in err?.response?.data) {
      message.error(err.response.data.non_field_errors, 3);
    }
    console.log(err);
  }
}

export function* postTemplateSaga() {
  yield takeEvery(POST_NEW_SMS_TEMPLATE, postTemplate);
}

export function* deleteTemplate() {
  yield takeEvery(DELETE_SMS_TEMPLATE, function* ({ payload, pageNumber }) {
    try {
      yield call(SmsBuilderService.deleteTemplate, payload);
      yield put(
        getTemplatesForPage({ page: pageNumber, perPage: NUMBER_PER_PAGE })
      );
    } catch (err) {
      console.log(err);
      message.error(err.response.data.message, 3);
    }
  });
}

export function* getTemplatesForPageSaga() {
  yield takeEvery(GET_ALL_SMS_TEMPLATES, function* ({ payload }) {
    try {
      const results = yield call(
        SmsBuilderService.getTemplatesForPage,
        payload
      );
      yield put(setAllTemplates(results));
    } catch (err) {
      console.log(err);
    }
  });
}

function* updateSmsTemplate({ id, payload, messageSuccess, resetModal }) {
  try {
    const updateTemp = yield call(SmsBuilderService.updateTemplate, {
      id,
      payload,
    });
    message.success(messageSuccess, 3);
    resetModal();
    yield put(updateTemplateList(updateTemp.data));
    return updateTemp;
  } catch (err) {
    console.log(err);
    if ("non_field_errors" in err.response.data) {
      message.error(err.response.data.non_field_errors, 3);
    }
  }
}

export function* updateSmsTemplateSaga() {
  yield takeEvery(UPDATE_SMS_TEMPLATE, updateSmsTemplate);
}

export function* updateSmsAndSendTemplateSaga() {
  yield takeEvery(
    UPDATE_SMS_TEMPLATE_AND_SEND,
    function* ({ id, payload, messageSuccess, resetModal, setSendSMSModallVisible }) {
      try {
        let response = yield updateSmsTemplate({ id, payload, messageSuccess, resetModal });
        yield put(setSelectedTemplate(response.data));
        setSendSMSModallVisible();
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors, 3);
        }
        console.log(err);
      }
    }
  );
}

export function* testSendSms() {
  yield takeEvery(
    TEST_SEND_SMS,
    function* ({ payload, messageSuccess, messageError }) {
      try {
        yield call(SmsBuilderService.testSendSms, payload);
        message.success(messageSuccess, 3);
      } catch (err) {
        message.error(messageError, 3);
        console.log(err);
    }
  });
}

export function* sendMassSmsSaga() {
  yield takeEvery(SEND_MASS_SMS, function* ({ payload, messageSuccess }) {
    try {
      yield call(SmsBuilderService.sendMassSms, payload);
      message.success(messageSuccess, 3);
    } catch (err) {
      console.log(err);
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* postAndSendNowTemplate() {
  yield takeEvery(
    POST_NEW_TEMPLATE_AND_SEND_NOW,
    function* ({ payload, messageSuccess, resetModal, setSendSMSModallVisible }) {
      try {
        let result = yield postTemplate({ payload, messageSuccess, resetModal });
        yield put(setSelectedTemplate(result.data));
        setSendSMSModallVisible();
      } catch (err) {
        console.log("err", err)
      }
    }
  );
};

export function* createSpecialSmsTemplateSaga() {
  yield takeEvery(CREATE_NEW_DEFAULT_TEMPLATE, function* ({payload, successMessage}) {
    try {
      yield call(SmsBuilderService.postNewSpecialTemplate, payload);
      message.success(successMessage, 3);
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors, 3);
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(postTemplateSaga),
    fork(deleteTemplate),
    fork(getTemplatesForPageSaga),
    fork(updateSmsTemplateSaga),
    fork(testSendSms),
    fork(sendMassSmsSaga),
    fork(postAndSendNowTemplate),
    fork(updateSmsAndSendTemplateSaga),
    fork(createSpecialSmsTemplateSaga),
  ]);
}
