import { defineMessages } from "react-intl";

export const scope = "activity";

export default defineMessages({
  filterText: {
    id: `${scope}.filter.text`,
    defaultMessage: "Filter",
  },
  allText: {
    id: `${scope}.all.text`,
    defaultMessage: "All",
  },
  communicationText: {
    id: `${scope}.communication.text`,
    defaultMessage: "Communication",
  },
  callsText: {
    id: `${scope}.calls.text`,
    defaultMessage: "Calls",
  },
  smsText: {
    id: `${scope}.sms.text`,
    defaultMessage: "SMS",
  },
  emailsText: {
    id: `${scope}.emails.text`,
    defaultMessage: "Emails",
  },
  statusText: {
    id: `${scope}.status.text`,
    defaultMessage: "Status Changes",
  },
  appointmentsText: {
    id: `${scope}.appointments.text`,
    defaultMessage: "Appointments",
  },
  activityText: {
    id: `${scope}.activity.text`,
    defaultMessage: "Contact Activity",
  },
  addsText: {
    id: `${scope}.adds.text`,
    defaultMessage: "Adds Activity",
  },
  emailTrackingText: {
    id: `${scope}.email.tracking.text`,
    defaultMessage: "Email Tracking",
  },
  formSubmissionText: {
    id: `${scope}.form.submission.text`,
    defaultMessage: "Form Submission",
  },
  marketingEmailsText: {
    id: `${scope}.marketing.emails.text`,
    defaultMessage: "Marketing Emails",
  },
  pageViewsText: {
    id: `${scope}.page.views.text`,
    defaultMessage: "Page Views",
  },
  phoneNumberText: {
    id: `${scope}.phone.number.text`,
    defaultMessage: "Phone Number: ",
  },
  emailText: {
    id: `${scope}.email.text`,
    defaultMessage: "Email: ",
  },
  sourceText: {
    id: `${scope}.source.text`,
    defaultMessage: "Source: ",
  },
  messageText: {
    id: `${scope}.message.text`,
    defaultMessage: "Message: ",
  },
  subjectText: {
    id: `${scope}.subject.text`,
    defaultMessage: "Subject: ",
  },
  awardCompleted: {
    id: `${scope}.award.completed.text`,
    defaultMessage: "Award completed",
  },
  awardRedeemed: {
    id: `${scope}.award.redeemed.text`,
    defaultMessage: "Award redeemed",
  },
  awardEdited: {
    id: `${scope}.award.edited.text`,
    defaultMessage: "Award edited",
  },
  awardExpired: {
    id: `${scope}.award.expired.text`,
    defaultMessage: "Award expired",
  },
  completedStatus: {
    id: `${scope}.completed.status`,
    defaultMessage: "Completed",
  },
  uncompletedStatus: {
    id: `${scope}.uncompleted.status`,
    defaultMessage: "Incomplete",
  },
  redeemedStatus: {
    id: `${scope}.redeemed.status`,
    defaultMessage: "Redeemed",
  },
  invoiceStatus: {
    id: `${scope}.invoiceStatus.text`,
    defaultMessage: "Status: {status} ",
  },
  invoiceDueDate: {
    id: `${scope}.invoiceDueDate.date`,
    defaultMessage: "Due Date: {date} ",
  },
  collectedForAward: {
    id: `${scope}.invoiceCollectedFor.award`,
    defaultMessage:
      "Amount from this payment will be added to award: {awardTitle} ",
  },
  invoiceUrl: {
    id: `${scope}.invoiceURL.url`,
    defaultMessage: "Invoice url: ",
  },
  inoviceLink: {
    id: `${scope}.invoiceLink.link`,
    defaultMessage: "link",
  },
  totalAmount: {
    id: `${scope}.totalAmount.invoice`,
    defaultMessage: "Total:",
  },
  refundAmount: {
    id: `${scope}.totalAmount.invoice`,
    defaultMessage: "Refund:",
  },
  subtotal: {
    id: `${scope}.subTotal.invoice`,
    defaultMessage: "Subtotal: ",
  },
  discount: {
    id: `${scope}.discount.invoice`,
    defaultMessage: "Discount:",
  },
  tax: {
    id: `${scope}.tax.invoice`,
    defaultMessage: "Tax:",
  },
  defaultAwardCompleted: {
    id: `${scope}.default.award.comeplted`,
    defaultMessage: "Default Award Completed",
  },
  defaultAwardRedeemed: {
    id: `${scope}.default.award.redeemed`,
    defaultMessage: "Default Award Redeemed",
  },
  campaignsText: {
    id: `${scope}.campaigns.text`,
    defaultMessage: "Campaigns",
  },
});
