import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import PageHeader from "components/layout-components/PageHeader";
import AppViews from "views/app-views";
import { Layout, Grid, Spin } from "antd";

import navigationConfig from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useLocation } from "react-router-dom";
import { FORMS, LANDING_PAGE_BUILDER } from "constants/PageSlugConstants";
import CallNotification from "components/layout-components/CallNotification";
const HeaderNav = lazy(() => import("components/layout-components/HeaderNav"));
const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location }) => {
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  // We need to remove "position: relative" from "app-content" when we are on the landing page builder.
  const pathSegments = useLocation().pathname.split("/");
  const currentPage = pathSegments[2];
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }
  return (
    <Suspense fallback={<Spin />}>
      <Layout>
        <CallNotification />
        <HeaderNav isMobile={isMobile} />
        {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-container">
          {isNavSide && !isMobile ? (
            <SideNav
              localization={false}
              routeInfo={currentRouteInfo}
              navigationConfig={navigationConfig}
            />
          ) : null}
          <Layout
            className="app-layout"
            style={{ paddingLeft: getLayoutGutter() }}
          >
            <div
              className={`app app-content ${isNavTop ? "layout-top-nav" : ""} ${
                currentPage === LANDING_PAGE_BUILDER || currentPage === FORMS
                  ? "static"
                  : ""
              }`}
            >
              <PageHeader
                display={currentRouteInfo?.breadcrumb}
                title={currentRouteInfo?.title}
              />
              <Content>
                <AppViews />
              </Content>
            </div>
          </Layout>
        </Layout>
        {isMobile && <MobileNav navigationConfig={navigationConfig} />}
      </Layout>
    </Suspense>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
